.strategiescontainerBreadcrumb {
  padding-top: 60px !important;
}

.strategiescontainer,
.strategiescontainerBreadcrumb {
  width: 1280px;
  max-width: 1280px;
  margin: auto !important;
  padding-left: 10px;
}

.linkStyle {
  text-transform: capitalize;
  font-size: 14px;
  line-height: 30px;
  font-family: "Poppins", "Work Sans", "Helvetica";
  color: #40414f;
}

.lastLink {
  color: #40414f;
  cursor: default;
  font-size: 14px;
}
.lastLink > a {
  color: #40414f;
  cursor: default;
  font-size: 14px;
  font-weight: 600;
}

.lastLink > svg {
  display: none !important;
}

.lastLink:hover {
  color: #d3d2d2;
}

.breadcrumbWrap {
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
}

@media screen and (max-width: 700px) and (min-width: 400px) {
  .strategiescontainerBreadcrumb {
    width: 100% !important;
    margin: auto !important;
    padding-left: 20px;
    padding-top: 100px !important;
  }

  .strategiescontainer {
    padding-top: 20px;
  }

  .strategiescontainerBreadcrumb {
    padding-bottom: 0 !important;
    padding-top: 60px;
  }

  .containerPadding {
    padding-right: 20px;
  }
}

@media screen and (max-width: 400px) and (min-width: 100px) {
  .linkStyle {
    text-transform: capitalize;
    font-size: 12px;
    line-height: 20px;
    font-family: "Poppins", "Work Sans", "Helvetica";
    padding-right: 10px !important;
    color: #40414f;
  }
  
  .lastLink {
    color: #40414f;
    cursor: default;
    font-size: 12px;
  }
  .lastLink > a {
    color: #40414f;
    cursor: default;
    font-size: 12px;
    font-weight: 600;
  }

  
}