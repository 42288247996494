@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  width: 0.6em;
  height: 0.7em;
}

body::-webkit-scrollbar-track {
  background-color: #f3f4f6;
}

body::-webkit-scrollbar-thumb {
  background-color: #b7b7c2;
  outline: 1px solid #b7b7c2;
  border-radius: 2px;
}
.custom-scroll::-webkit-scrollbar {
  width: 0.4em;
  height: 0.6em;
}
 
.custom-scroll::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: #f3f4f6;
}
 
.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #b7b7c2;
  outline: 1px solid #b7b7c2;
  border-radius: 2px;
}

.messages__wrapper {
  overflow-y: scroll;
  margin-top: 10rem;
}

.answer__box {
  background: orange;
  height: 180px;
  width: 400px;
  margin: 10px -400px;
  -webkit-animation-name: move;
  -webkit-animation-duration: 4s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: right;
  -webkit-animation-timing-function: linear;
}

.answer__box:hover {
  -webkit-animation-play-state: paused;
}

@-webkit-keyframes move {
  0% {
    margin-left: -400px;
  }
  100% {
    margin-left: 800px;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .info-text {
    @apply text-sm font-medium font-poppins py-2.5 px-4 rounded-xl shadow-sm hover:shadow-sm  hover:bg-gray-100 cursor-pointer transition ease-in duration-300;
  }

  .core-btn {
    @apply text-lg text-center   text-[#40414f] font-medium font-poppins  px-2 py-2  bg-white border-[1px] border-[#d9d9e3] rounded-md w-[120px] hover:bg-gray-100 cursor-pointer  transition ease-in duration-300;
  }
  .further-btn {
    @apply text-lg text-center   text-[#40414f] font-medium font-poppins  px-2 py-2  bg-white border-[1px] border-[#d9d9e3] rounded-md w-[120px]  hover:bg-gray-100 cursor-pointer transition ease-in duration-300;
  }
  .login-btn {
    @apply flex items-center text-sm font-semibold font-poppins rounded-md px-6 py-2 bg-white border-[1px] border-[#d9d9e3] hover:bg-gray-100 cursor-pointer text-[#40414f] transition-all duration-200 ease-in-out;
  }

  .truncate-text {
    @apply overflow-hidden whitespace-nowrap text-ellipsis;
  }
}

textarea:focus,
option:focus,
select:focus,
input:focus {
  outline: none;
}

.fade-in-hello {
  animation: fade 1000ms ease-in-out;
}

@keyframes fade {
  0% {
    opacity: 0%;
  }
}

.blinking-cursor {
  width: 10px;
  margin-left: 5px;
  background-color: #000000;
  animation: blink 1s infinite;
  border-right: 2px solid rgb(58, 57, 57);
}

@keyframes cursorBlink {
  0% {
    border-right-color: initial;
  }

  to {
    border-right-color: transparent;
  }
}

.cursor-blink {
  border-width: 0px;
  border-style: solid;
  border-color: black;
  border-right-width: 8px;
  white-space: pre-wrap;
  animation: cursorBlink 0.6s ease-in-out infinite alternate;
}

.cssanimation,
.cssanimation span {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.cssanimation span {
  display: inline-block;
}

.fadeInBottom {
  animation-name: fadeInBottom;
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
  }
}

.blink {
  width: 20px;
  height: 30px;
  background: rgba(0, 0, 0, 0.4);
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
